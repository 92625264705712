import { GetServerSideProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo } from 'next-seo';

import Template from 'templates/Default';

import PageForm from 'pages/Auth/Login/Form';
import routeMiddleware from 'security/route-middleware';

import i18nFiles from 'config/i18n-files';
import seo from 'config/seo';

const pageLang = 'auth-login';

const MyPageForm: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <NextSeo title={`${seo().title} - ${t(`${pageLang}:title`)}`} />

      <Template>
        <PageForm />
      </Template>
    </>
  );
};

export default MyPageForm;

export const getServerSideProps: GetServerSideProps = async (context) => {
  const routeData = await routeMiddleware({ context });
  if (routeData) return routeData;

  return {
    props: {
      ...(await serverSideTranslations(context.locale || 'ptBR', i18nFiles)),
    },
  };
};
